<template>
  <!-- 联系方式区块容器 -->
  <article class="contact-section">
    <!-- 联系信息消息容器 -->
    <div class="contact-message">
      <span>
        <!-- 联系信息文本 -->
        <span class="contact-message-text">
          {{ currentLanguageConfig.coontactSection.content }}
          <br/>
        </span>
        <!-- 联系邮箱地址 -->
        <span class="contact-email">
          magic.gourd.app@gmail.com
        </span>
      </span>
    </div>
  </article>
</template>

<script>
// 定义联系方式区块组件
import {useLanguageStore} from "@/stores/language";
import {computed} from "vue";
import locales from "@/locales";

export default {
  name: "ContactSection",
  setup() {
    const languageStore = useLanguageStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })

    return {
      currentLanguageConfig
    }
  }
}
</script>

<style scoped lang="scss">
// 联系方式区块样式
.contact-section {
  padding: 6.25rem 0.625rem; // 100px 10px
  display: flex;
  flex-direction: row;
  gap: 0.625rem; // 10px
  align-items: center;
  justify-content: center;
  //position: absolute;  // 已注释掉的绝对定位
  //left: 405px;
  //top: 4702px;
  @media only screen and (max-width: 48rem) {
    padding: 2rem 2rem;
  }
}

// 联系信息消息样式
.contact-message {
  color: #000000;
  text-align: center;
  position: relative;

  // 联系信息文本样式
  &-text {
    color: #000000;
    font-family: "Inter-Regular", sans-serif;
    font-size: 1rem; // 16px
    font-weight: 400;
  }
}

// 联系邮箱地址样式
.contact-email {
  color: #000000;
  font-family: "Inter-Regular", sans-serif;
  font-size: 1.5rem; // 24px
  font-weight: 400;
}
</style>