<template>
  <article class="core-features">
    <h1 class="feature-title">
      <span v-if="currentLang === 'zh'">
        {{ currentLanguageConfig.coreFeatures.title1 }}
        <br>
        {{ currentLanguageConfig.coreFeatures.title2 }}
      </span>
      <span v-else>
        {{ currentLanguageConfig.coreFeatures.title }}
      </span>
    </h1>
    <div class="feature-description">
      <span>
        {{ currentLanguageConfig.coreFeatures.description }}
      </span>
    </div>
    <div v-if="!isMobileDevice" class="browser-extensions">
      <div class="extension-chrome"
           @click="openTab('https://chromewebstore.google.com/detail/cgnjokgoiepncjbgbjnihcpekgimnmaf')">
        <div class="extension-button">
          <div class="button-content">
            <img class="icon-chrome" src="@/assets/images/icon-chrome0.png"/>
            <div class="button-text">{{ currentLanguageConfig.coreFeatures.add1 }}</div>
          </div>
        </div>
        <img class="rating-stars" src="@/assets/images/stars0.png"/>
      </div>
      <div class="extension-edge"
           @click="openTab('https://microsoftedge.microsoft.com/addons/detail/kmoaicpbmfhbocapfopeakkcaoneebhm')">
        <div class="extension-button">
          <div class="button-content">
            <img class="icon-edge" src="@/assets/images/icon-chrome1.png"/>
            <div class="button-text">{{ currentLanguageConfig.coreFeatures.add2 }}</div>
          </div>
        </div>
        <img class="rating-stars" src="@/assets/images/stars1.png"/>
      </div>
      <div class="extension-firefox"
           @click="openTab('https://addons.mozilla.org/firefox/addon/magic-gourd/')">
        <div class="extension-button">
          <div class="button-content">
            <img class="icon-firefox" src="@/assets/images/icon-firefox0.png"/>
            <div class="button-text">{{ currentLanguageConfig.coreFeatures.add3 }}</div>
          </div>
        </div>
        <img class="rating-stars" src="@/assets/images/stars2.png"/>
      </div>
      <div class="extension-safari">
        <div class="extension-button to-up">
          <div class="button-content">
            <img class="icon-safari" src="@/assets/images/icon-safari-ios0.png"/>
            <div class="button-text">{{ currentLanguageConfig.coreFeatures.add4 }}</div>
          </div>
        </div>
        <div class="coming-soon">{{ currentLanguageConfig.coreFeatures.comming }}</div>
        <img class="rating-stars" style="visibility: hidden;" src="@/assets/images/stars2.png"/>
      </div>
    </div>
    <div v-else class="browser-extensions" style="flex-direction: column;">
      <div class="button-content">
        <img class="icon-chrome" src="@/assets/images/icon-chrome0.png"
             @click="openTab('https://chromewebstore.google.com/detail/cgnjokgoiepncjbgbjnihcpekgimnmaf')"/>
        <img class="icon-edge" src="@/assets/images/icon-chrome1.png"
             @click="openTab('https://microsoftedge.microsoft.com/addons/detail/kmoaicpbmfhbocapfopeakkcaoneebhm')"/>
        <img class="icon-firefox" src="@/assets/images/icon-firefox0.png"
             @click="openTab('https://addons.mozilla.org/firefox/addon/magic-gourd/')"/>
        <img class="icon-safari" style="opacity: 0.5;" src="@/assets/images/icon-safari-ios0.png"/>
      </div>
      <div style="display: flex;flex-direction: row;align-items: center;
     justify-content: center;flex-shrink: 0;height: auto;"
      >
        <img class="rating-stars" src="@/assets/images/stars1.png"/>
      </div>
    </div>
    <div class="main-features">
      <div class="feature-card webpage">
        <img class="feature-icon" src="@/assets/images/image0.png"/>
        <div class="feature-name">{{ currentLanguageConfig.coreFeatures.webPageName }}</div>
        <div class="feature-detail">
          {{ currentLanguageConfig.coreFeatures.webPageDetail }}
        </div>
      </div>
      <div class="feature-card pdf">
        <img class="feature-icon" src="@/assets/images/pdf0.png"/>
        <div class="feature-name">{{ currentLanguageConfig.coreFeatures.pdfName }}</div>
        <div class="feature-detail">
          {{ currentLanguageConfig.coreFeatures.pdfPageDetail }}
        </div>
      </div>
      <div class="feature-card youtube">
        <img class="feature-icon" src="@/assets/images/youtube0.png"/>
        <div class="feature-name">{{ currentLanguageConfig.coreFeatures.youtubeName }}</div>
        <div class="feature-detail">
          {{ currentLanguageConfig.coreFeatures.youtubeDetail }}
        </div>
      </div>
      <div class="feature-card bilibili">
        <img class="feature-icon" src="@/assets/images/bilibili0.png"/>
        <div class="feature-name">{{ currentLanguageConfig.coreFeatures.bilibiliName }}</div>
        <div class="feature-detail">
          {{ currentLanguageConfig.coreFeatures.bilibiliDetail }}
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {isMobile} from "@/utils/device";
import {computed} from 'vue'
import {useLanguageStore} from '@/stores/language'
import locales from '@/locales'
import {message} from 'ant-design-vue';


export default {
  name: "CoreFeatures.vue",
  computed: {
    isMobileDevice() {
      return isMobile();
    }
  },
  setup() {
    const languageStore = useLanguageStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })

    const currentLang = computed(() => {
      return languageStore.currentLang;
    })

    const warning = () => {
      message.warning('正在上架，敬请期待...');
    };
    const openTab = (url) => {
      window.open(url);
    };

    return {
      currentLanguageConfig,
      currentLang,
      warning,
      openTab
    }
  }
}
</script>

<style scoped lang="scss">
/*待上架*/
.to-up {
  background: #E0E0E0 !important;
}

.core-features {
  padding: 10rem 7.44rem 6.25rem 7.44rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;

  // 移动端适配
  @media only screen and (max-width: 48rem) {
    gap: 2rem;
    padding: 7rem 1.5rem 2rem;
  }
}

.feature-title {
  color: #2c313a;
  text-align: center;
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 2.75rem;
  font-weight: 600;
  position: relative;
  width: 41.75rem;
  height: 6.625rem;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    font-size: 1.75rem;
    width: 100%;
    height: auto;
  }
}

.feature-description {
  color: #4a5263;
  text-align: center;
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  position: relative;
  width: 42.8125rem;
  padding: 0 1rem;
  word-break: keep-all;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    font-size: 1rem;
    width: 100%;
  }
}

.browser-extensions {
  display: flex;
  flex-direction: row;
  gap: 0.6875rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    flex-wrap: wrap;
    justify-content: center;
    //align-items: center;
  }
}

.extension-chrome,
.extension-edge,
.extension-firefox {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  align-items: center;
  justify-content: flex-start;
  //flex-shrink: 0;
  position: relative;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    gap: 0.625em;
  }
}

.extension-button {
  background: #ffffff;
  border-radius: 0.625rem;
  border: 1px solid #e0e0e0;
  padding: 0.5625rem 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 3.5rem;
  position: relative;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  cursor: pointer;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    height: 3rem;
    padding: 0.5rem;
  }
}

.button-content {
  display: flex;
  flex-direction: row;
  gap: 0.625em;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.icon-chrome,
.icon-edge,
.icon-firefox,
.icon-safari {
  flex-shrink: 0;
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  overflow: visible;
  cursor: pointer;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    width: 2rem;
    height: 2rem;
  }
}

.button-text {
  color: #000000;
  text-align: center;
  font-family: "Inter-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
}

.rating-stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
  width: 9rem;
}

.extension-safari {
  display: flex;
  flex-direction: column;
  gap: 1.5625em;
  align-items: center;
  justify-content: flex-start;
  //flex-shrink: 0;
  //width: 11.875rem;
  position: relative;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    width: auto;
    gap: 0.625em;
  }
}

.coming-soon {
  color: #4a5263;
  text-align: center;
  font-family: "Inter-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  align-self: stretch;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    font-size: 0.875rem;
  }
}

.main-features {
  display: flex;
  flex-direction: row;
  gap: 0.875rem;
  align-items: stretch;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;


  // 移动端适配
  @media screen and (max-width: 48rem) {
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}

.feature-card {
  background: #ffffff;
  border-radius: 0.625rem;
  //padding: 2.8125rem 2.375rem;
  padding: 2.8125rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.0625rem;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  //width: 18.125rem;
  width: 24.5%;
  //height: 20.6875rem;
  position: relative;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 48rem) {
    width: 10.4rem;
    min-height: auto;
    padding: 1rem 0.5rem;
    height: auto;
  }
}

.feature-icon {
  flex-shrink: 0;
  width: 5.75rem;
  height: 5.75rem;
  position: relative;
  object-fit: cover;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    width: 4rem;
    height: 4rem;
  }
}

.feature-name {
  color: #000000;
  text-align: center;
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  position: relative;

  // 移动端适配
  @media screen and (max-width: 48rem) {
    font-size: 1rem;
  }
}

.feature-detail {
  color: #4a5263;
  text-align: center;
  font-family: "Inter-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  //width: 13.1875rem;
  //height: 4.4375rem;
  //word-break: keep-all;
  // 移动端适配
  @media screen and (max-width: 48rem) {
    font-size: 0.75rem;
    width: 100%;
    height: 4rem;
  }
}
</style>