import {defineStore} from 'pinia'

export const useLanguageStore = defineStore('language', {
    state: () => ({
        currentLang: (navigator.language || navigator.userLanguage) && /^zh/.test((navigator.language || navigator.userLanguage).toLowerCase()) ? 'zh' : 'en' // 默认英文
    }),

    actions: {
        setLanguage(lang) {
            this.currentLang = lang
        }
    }
}) 