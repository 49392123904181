import './assets/global.scss'; // 导入全局样式文件
import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
if ((navigator.language || navigator.userLanguage) && /^zh/.test((navigator.language || navigator.userLanguage).toLowerCase())) {
    document.title = '魔法葫芦 - 批注万物';
}
const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.mount('#app')
