<template>
  <div class="header">
    <div class="brand">
      <img class="logo" src="@/assets/images/logo-add-sparkle-remove-bg0.png"/>
      <div class="name">{{ currentLanguageConfig.navHeader.brandName }}</div>
    </div>
    <div class="language">
      <img class="icon" src="@/assets/images/locale-1-10.png"/>
      <!--      <div class="text">{{ currentLanguageConfig.navHeader.language }}</div>-->
      <a-dropdown :trigger="['click']">
        <a class="ant-dropdown-link" @click.prevent>
          {{ currentLanguageConfig.navHeader.language }}
          <DownOutlined/>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="changeLang('en')">
              <a href="javascript:;">English</a>
            </a-menu-item>
            <a-menu-item @click="changeLang('zh')">
              <a href="javascript:;">中文简体</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <!--      <img class="dropdown" src="@/assets/images/down-1-10.png"/>-->
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useLanguageStore} from '@/stores/language'
import locales from '@/locales'
import {Dropdown} from 'ant-design-vue';
import {Menu} from 'ant-design-vue';
import {MenuItem} from 'ant-design-vue';
import {DownOutlined} from '@ant-design/icons-vue';

export default {
  name: "NavHeader",
  components: {
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: MenuItem,
    DownOutlined
  },
  setup() {
    const languageStore = useLanguageStore()

    const languages = [
      {code: 'en', name: 'English'},
      {code: 'zh', name: '中文'}
    ]

    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })
    // 切换语言
    const changeLang = (lang) => {
      languageStore.setLanguage(lang);
      // 设置当前页面title
      if (lang === 'zh') {
        document.title = '魔法葫芦 - 批注万物';
      } else {
        document.title = 'MagicGourd - Annotate anything';
      }
    }

    return {
      languages,
      changeLang,
      currentLanguageConfig
    }
  }
}
</script>

<style scoped lang="scss">
:deep(*) {
  .ant-dropdown-menu-title-content {
    font-size: 1.05rem;
    @media only screen and (max-width: 48rem) {
      font-size: 0.8rem;
    }
  }
}

.header {
  border-style: solid;
  border-color: #eef0f3;
  border-width: 0 0 0.0625rem 0;
  padding: 1.2rem 7.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: #ffffff;
  overflow: hidden;
}

.brand {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 15.7rem;
  height: 2.56rem;
  position: relative;
}

.logo {
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  object-fit: cover;
}

.name {
  color: #333333;
  text-align: left;
  font-family: "Inter-Bold", sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  position: relative;
}

.language {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  margin-left: 1rem;

  color: #000000;
  text-align: left;
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;

  .ant-dropdown-link {
    width: 6.7rem;
    @media only screen and (max-width: 48rem) {
      width: 5.3rem;
    }
  }
}

.icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  object-fit: cover;
}

.text {
  color: #000000;
  text-align: left;
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  position: relative;
}

.dropdown {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  object-fit: cover;
}

// 适配移动端屏幕
@media only screen and (max-width: 48rem) {
  .header {
    padding: 1rem;
  }

  .brand {
    width: auto;
  }

  .name {
    font-size: 1rem;
  }

  .text {
    font-size: 1rem;
  }
  .language {
    font-size: 1rem;
  }
  .logo {
    width: 1.35rem;
    height: 1.35rem;
  }

  .icon {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #eef0f3;
  border-radius: 4px;
  margin-top: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.language-item {
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
}

.language {
  position: relative;
  cursor: pointer;
}
</style>