export default {
    en: {
        navHeader: {
            language: 'English',
            brandName: 'MagicGourd'
        },
        coreFeatures: {
            title: "Annotate anything you want in one place",
            description: "Add text or image highlights, notes, and tags to webpages, PDFs, YouTube,\n" +
                "      and Bilibili in your favorite browser.",
            add1: "Add to Chrome",
            add2: "Add to Edge",
            add3: "Add to Firefox",
            add4: "Add to Safari",
            comming: "coming soon ...",
            webPageName: "Webpage",
            webPageDetail: "Highlight and annotate any webpage effortlessly to save key information.",
            pdfName: "PDFs",
            pdfPageDetail: "Mark up PDFs with text or crop image to keep your documents organized.",
            youtubeName: "YouTube",
            youtubeDetail: "Tag specific moments on YouTube videos to capture insights like never before.",
            bilibiliName: "Bilibili",
            bilibiliDetail: "Highlight your favorite moments to keep track of the valuable knowledge."
        },
        featureShowCase: {
            pdfTitle: "Highlight directly on Webpages and PDFs in your favorite browser.",
            pdfDescription: "Effortlessly highlight text or image on the web or in PDFs using\n" +
                "          MagicGourd. Enhance your highlights with notes and tags for better\n" +
                "          organization and accessibility.",
            videoTitle: "Tag specific moments on YouTube and Bilibili videos to capture insights.",
            videoDescription: "Never miss a key moment—easily capture valuable insights from your\n" +
                "          favorite videos and revisit them anytime with just a single click.",
            crossTitle: "Work seamlessly across desktop, tablet, and mobile.",
            crossDescription: "Experience the flexibility of MagicGourd on any device—whether you're\n" +
                "          using a desktop, tablet, or mobile. Highlight, annotate, and organize\n" +
                "          your content effortlessly, anytime and anywhere."
        },
        moreFeature: {
            name: "More Features",
            featureList: [
                {
                    title: "Highlight Images",
                    description: "Highlight images on webpages, PDFs, and videos.",
                    image: "image-21.png"
                },
                {
                    title: "Take Notes",
                    description: "Add notes to any highlight or content, including webpages, PDFs, and videos.",
                    image: "notes0.png"
                },
                {
                    title: "Organize with Tags",
                    description: "Easily categorize and retrieve content by adding custom tags to highlights or pages.",
                    image: "tag-fill0.png"
                },
                {
                    title: "Quick Search",
                    description: "Find content fast with keywords, filters, and sorting for pages or highlights.",
                    image: "search0.png"
                },
                {
                    title: "Unread List",
                    description: "Keep track of unread files, notes, and highlights in one place for easy access.",
                    image: "md-mail-unread0.png"
                },
                {
                    title: "Export to Markdown",
                    description: "Convert and export your highlights and notes to Markdown files with a single click.",
                    image: "export0.png"
                },
                {
                    title: "Quoteshots",
                    description: "Transform your highlights into stunning quote images, ready to share.",
                    image: "quote-20.png"
                },
                {
                    title: "End-to-End Encryption",
                    description: "Secure your notes with end-to-end encryption, ensuring your data stays private.",
                    image: "div8.png"
                },
                {
                    title: "Offline Mode",
                    description: "You can make annotations offline once the page is fully loaded.",
                    image: "cloud-offline0.png"
                },
                {
                    title: "Dark Mode",
                    description: "Automatically adapts to your system’s dark mode for a better viewing experience.",
                    image: "dark-mode0.png"
                },
                {
                    title: "Multi-Language",
                    description: "Use the interface in your preferred language with seamless multilingual support.",
                    image: "locale0.png"
                },
                {
                    title: "Cross-Device Sync",
                    description: "Access and update your data seamlessly across all your devices.",
                    image: "cloud-sync-20.png"
                }
            ]
        },
        testimonialSection: {
            name: "Loved by users worldwide",
            subtitle: "MagicGourd is used and loved by users worldwide. People from bestseller\n" +
                "        authors, researchers, and students use MagicGourd to enhance their\n" +
                "        research and learning.",
            testimonials: [
                {
                    content: "MagicGourd has completely transformed the way I organize my ideas. Highlighting and note-taking have never been this intuitive and seamless. It's like having a personal assistant for all my research projects!",
                    avatar: require("@/assets/images/image1.png"),
                    authorName: "Olivia Harper",
                    authorRole: "Writer"
                },
                {
                    content: "I rely on MagicGourd daily to capture and sort through my reading highlights. The ease of syncing across devices makes it my go-to tool for staying productive wherever I am.",
                    avatar: require("@/assets/images/image2.png"),
                    authorName: "Liam Turner",
                    authorRole: "Tech Entrepreneur"
                },
                {
                    content: "MagicGourd has been a game-changer for my teaching prep. The ability to annotate and revisit my materials offline ensures I'm always ready, even in the most unpredictable circumstances.",
                    avatar: require("@/assets/images/image3.png"),
                    authorName: "Sophia Brooks",
                    authorRole: "Educator"
                },
                {
                    content: "I love how MagicGourd makes it so simple to revisit ideas and inspirations. It's helped me stay creative and organized while working on multiple projects at once.",
                    avatar: require("@/assets/images/image4.png"),
                    authorName: "Ava Johnson",
                    authorRole: "Freelance Designer"
                },
                {
                    content: "With MagicGourd, I can finally keep all my research notes in one place. The Markdown export feature is a lifesaver for preparing presentations and sharing insights with my team.",
                    avatar: require("@/assets/images/image5.png"),
                    authorName: "Ethan Walker",
                    authorRole: "Data Scientist"
                },
                {
                    content: "MagicGourd lets me curate and share insights effortlessly. The quote-shot feature is a brilliant addition—it makes sharing ideas visually appealing and impactful!",
                    avatar: require("@/assets/images/image6.png"),
                    authorName: "Mason Bennett",
                    authorRole: "Marketing Specialist"
                },
                {
                    content: "MagicGourd is the perfect companion for academic research. The powerful search and filter options help me quickly locate important references and save hours of work.",
                    avatar: require("@/assets/images/image7.png"),
                    authorName: "Emma Reed",
                    authorRole: "PhD Student"
                },
                {
                    content: "I can't imagine managing my learning process without MagicGourd anymore. The interface is clean, the features are robust, and the cross-device sync works like a charm.",
                    avatar: require("@/assets/images/image8.png"),
                    authorName: "Noah Carter",
                    authorRole: "Startup Founder"
                },
                {
                    content: "MagicGourd keeps all my notes from articles, travel guides, and videos in one place. It's the ultimate productivity tool for anyone who's constantly on the go!",
                    avatar: require("@/assets/images/image9.png"),
                    authorName: "Isabella Wright",
                    authorRole: "Travel Blogger"
                }
            ]
        },
        coontactSection: {
            content: "Our product is rapidly improving. If you have any ideas, feel free to\n" +
                "          contact us at:"
        },
        footer: {
            downloadTitle: "Download",
            chromeExtension: "Chrome Extension",
            chromeUrl:"https://chromewebstore.google.com/detail/cgnjokgoiepncjbgbjnihcpekgimnmaf",
            edgeExtension: "Edge Extension",
            edgeUrl:"https://microsoftedge.microsoft.com/addons/detail/kmoaicpbmfhbocapfopeakkcaoneebhm",
            firefoxExtension: "Firefox Extension",
            firefoxUrl:"https://addons.mozilla.org/firefox/addon/magic-gourd/",
            safariExtension: "Safari Extension (coming soon...)",
            safariUrl: "",
            documentTitle: "Document",
            helpCenter: "Help Center",
            helpUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/gu6qpetgyzvbyocm",
            termsOfService: "Terms of Service",
            termsOfServiceUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/mqzag3ovd3vl34b9",
            privacyPolicy: "Privacy Policy",
            privacyPolicyUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/dd3o6dtupcnuxher",
        }
    },
    zh: {
        navHeader: {
            language: '中文简体',
            brandName: '魔法葫芦'
        },
        coreFeatures: {
            title1: "批注万物",
            title2: "魔法自然显现",
            description: "在你喜爱的浏览器中，为网页、PDF、YouTube 和哔哩哔哩添加文字\n" +
                "      或图片高亮、笔记和标签。",
            add1: "添加到 Chrome",
            add2: "添加到 Edge",
            add3: "添加到 Firefox",
            add4: "添加到 Safari",
            comming: "正在上架...",
            webPageName: "网页",
            webPageDetail: "高亮和标注任意网页，一键标记文字、图片等关键信息，便于日后查找。",
            pdfName: "PDF",
            pdfPageDetail: "在浏览器中打开 PDF 并高亮文字，支持拉框标注图片、公式、表格等复杂内容。",
            youtubeName: "YouTube",
            youtubeDetail: "为 YouTube 视频的任意时刻添加标签，随时记录触动的画面，留下观看印记。",
            bilibiliName: "哔哩哔哩",
            bilibiliDetail: "在哔哩哔哩视频中随时标记高亮帧，通过时间轴的水滴标记快速复习关键内容。"
        },
        featureShowCase: {
            pdfTitle: "浏览器变阅读器，随时批注和笔记",
            pdfDescription: "轻松在任意网页原文或 PDF 上高亮文字（或图片），无论是重要段落还是关键信息都能一键标注。还可以为高亮内容添加笔记和标签，帮助你更高效地组织和分类，让信息查阅更加便捷无忧。",
            videoTitle: "观看视频，捕捉洞见，拒绝走马观花",
            videoDescription: "为 YouTube 和哔哩哔哩视频的特定时刻添加标签，精准捕捉关键帧画面。不再错过任何精彩瞬间——轻松记录宝贵信息，并可随时一键回顾。",
            crossTitle: "任意浏览器，任意设备，纵享丝滑",
            crossDescription: "魔法葫芦适配了不同大小的屏幕，无论是桌面、平板还是手机，都能轻松高亮、标注和整理内容，让你随时随地高效管理信息。"
        },
        moreFeature: {
            name: "更多特性",
            featureList: [
                {
                    title: "图片高亮",
                    description: "在任意网页或PDF上添加图片高亮；在 YouTube 或 Bilibili 视频添加高亮画面帧。",
                    image: "image-21.png"
                },
                {
                    title: "记录笔记",
                    description: "对任意高亮或内容（网页、PDF、视频）添加笔记或备注，随时记录灵感。",
                    image: "notes0.png"
                },
                {
                    title: "添加标签",
                    description: "对任意高亮或内容（网页、PDF、视频）添加标签，分类整理让知识井井有条。",
                    image: "tag-fill0.png"
                },
                {
                    title: "快捷搜索",
                    description: "支持所有历史记录进行关键词搜索，并且可以设置多种过滤条件以及排序逻辑。",
                    image: "search0.png"
                },
                {
                    title: "未读列表",
                    description: "尚未读完的内容，担心错过，可以标记为未读，从而可以在未读列表快速查看。",
                    image: "md-mail-unread0.png"
                },
                {
                    title: "导出 Markdown",
                    description: "支持一键将笔记连同高亮内容、网页信息导出为 Markdown 格式，便于其他笔记软件粘贴。",
                    image: "export0.png"
                },
                {
                    title: "引用卡片",
                    description: "将任意高亮内容导出为精美的分享卡片，分享到社交平台，或者收藏到个人相册。",
                    image: "quote-20.png"
                },
                {
                    title: "端到端加密",
                    description: "所有笔记、高亮、历史记录信息均采用了端到端加密技术，确保隐私安全。",
                    image: "div8.png"
                },
                {
                    title: "离线模式",
                    description: "一旦网页内容完成加载，即使设备临时离线，也可以继续批注，上线后自动同步数据。",
                    image: "cloud-offline0.png"
                },
                {
                    title: "深色模式",
                    description: "软件界面适配了深色模式和浅色模式，自动更随系统，让阅读过程更舒适。",
                    image: "dark-mode0.png"
                },
                {
                    title: "多语言",
                    description: "已经完成中文简体、中文繁体、英文的适配，并自动更随系统语言。",
                    image: "locale0.png"
                },
                {
                    title: "跨设备数据同步",
                    description: "当使用多台设备时，设备之间的数据会自动保持同步。",
                    image: "cloud-sync-20.png"
                }
            ]
        },
        testimonialSection: {
            name: "广受用户好评",
            subtitle: "魔法葫芦受到来自全球用户的喜爱。无论是畅销书作家、研究人员，还是学生，都在使用魔法葫芦来提升他们的研究和学习效率。",
            testimonials: [
                {
                    content: "魔法葫芦完全改变了我整理想法的方式。高亮和做笔记从未如此直观流畅，就像为我的所有研究项目配备了一位私人助理！",
                    avatar: require("@/assets/images/image1.png"),
                    authorName: "Olivia Harper",
                    authorRole: "作家"
                },
                {
                    content: "我每天都依赖魔法葫芦来捕捉和整理我的阅读高亮。跨设备同步的便捷性，让它成为我随时随地保持高效的首选工具。",
                    avatar: require("@/assets/images/image2.png"),
                    authorName: "Liam Turner",
                    authorRole: "科技创业者"
                },
                {
                    content: "魔法葫芦为我的教学准备带来了革命性的改变。可以离线标注和回顾我的材料，确保即使在最不可预测的情况下，我也能随时做好准备。",
                    avatar: require("@/assets/images/image3.png"),
                    authorName: "Sophia Brooks",
                    authorRole: "教师"
                },
                {
                    content: "我非常喜欢魔法葫芦让重温想法和灵感变得如此简单。它帮助我在同时处理多个项目时，既保持创造力又井井有条。",
                    avatar: require("@/assets/images/image4.png"),
                    authorName: "Ava Johnson",
                    authorRole: "自由职业设计师"
                },
                {
                    content: "有了魔法葫芦，我终于可以将所有的研究笔记集中在一个地方。Markdown 导出功能对准备演示文稿和与团队分享见解来说简直是救星。",
                    avatar: require("@/assets/images/image5.png"),
                    authorName: "Ethan Walker",
                    authorRole: "数据科学家"
                },
                {
                    content: "魔法葫芦让我可以轻松整理和分享见解。引言截图功能真是个绝妙的创新——它让分享想法既美观又有冲击力！",
                    avatar: require("@/assets/images/image6.png"),
                    authorName: "Mason Bennett",
                    authorRole: "市场营销"
                },
                {
                    content: "魔法葫芦是学术研究的完美助手。强大的搜索和筛选功能让我能够快速找到重要参考文献，节省了大量时间。",
                    avatar: require("@/assets/images/image7.png"),
                    authorName: "Emma Reed",
                    authorRole: "博士生"
                },
                {
                    content: "我已经无法想象没有魔法葫芦来管理我的学习过程了。界面简洁、功能强大，跨设备同步也非常顺畅。",
                    avatar: require("@/assets/images/image8.png"),
                    authorName: "Noah Carter",
                    authorRole: "创业者"
                },
                {
                    content: "魔法葫芦将我从文章、旅行指南和视频中记录的所有笔记集中在一个地方。对于经常奔波的人来说，它是终极高效工具！",
                    avatar: require("@/assets/images/image9.png"),
                    authorName: "Isabella Wright",
                    authorRole: "旅行博主"
                }
            ]
        },
        coontactSection: {
            content: "我们的产品正在快速优化中。如果您有任何想法，欢迎随时联系我们："
        },
        footer: {
            downloadTitle: "下载",
            chromeExtension: "Chrome 插件",
            chromeUrl:"https://chromewebstore.google.com/detail/cgnjokgoiepncjbgbjnihcpekgimnmaf",
            edgeExtension: "Edge 插件",
            edgeUrl:"https://microsoftedge.microsoft.com/addons/detail/kmoaicpbmfhbocapfopeakkcaoneebhm",
            firefoxExtension: "Firefox 插件",
            firefoxUrl:"https://addons.mozilla.org/firefox/addon/magic-gourd/",
            safariExtension: "Safari 插件 (正在上架...)",
            safariUrl: "",
            documentTitle: "文档",
            helpCenter: "帮助中心",
            helpUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/gu6qpetgyzvbyocm",
            termsOfService: "服务条款",
            termsOfServiceUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/mqzag3ovd3vl34b9",
            privacyPolicy: "隐私政策",
            privacyPolicyUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/dd3o6dtupcnuxher",
        }
    }
}