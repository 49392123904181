<template>
  <div id="app">
    <header>
      <NavHeader/>
    </header>
    <main aria-role="main" class="homepage">
      <section>
        <CoreFeatures/>
      </section>
      <section>
        <FeatureShowCase/>
      </section>
      <section>
        <MoreFeature/>
      </section>
      <section>
        <TestimonialSection/>
      </section>
      <section>
        <ContactSection/>
      </section>
    </main>
    <footer class="footer wrapper">
      <WebFooter/>
    </footer>
  </div>
</template>

<script>
import NavHeader from './components/NavHeader.vue'
import CoreFeatures from './components/CoreFeatures.vue'
import FeatureShowCase from './components/FeatureShowCase.vue'
import MoreFeature from './components/MoreFeature.vue'
import TestimonialSection from './components/TestimonialSection.vue'
import ContactSection from './components/ContactSection.vue'
import WebFooter from './components/WebFooter.vue'

export default {
  name: 'App',
  components: {
    NavHeader,
    CoreFeatures,
    FeatureShowCase,
    MoreFeature,
    TestimonialSection,
    ContactSection,
    WebFooter
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
