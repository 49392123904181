<template>
  <article class="more-features-container">
    <h1 class="more-features-title">{{ currentLanguageConfig.moreFeature.name }}🌟</h1>
    <div class="features-grid">
      <div class="feature-card" v-for="feature in currentLanguageConfig.moreFeature.featureList" :key="feature.title">
        <img class="feature-icon" :src="require(`@/assets/images/${feature.image}`)"/>
        <div class="feature-title">{{ feature.title}}</div>
        <div class="feature-description">
          {{ feature.description}}
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {computed} from 'vue'
import {useLanguageStore} from '@/stores/language'
import locales from '@/locales'

export default {
  name: "MoreFeature.vue",
  setup() {
    const languageStore = useLanguageStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })

    return {
      currentLanguageConfig
    }
  }
}

</script>

<style scoped lang="scss">
.more-features-container {
  padding: 6.25rem 0;
  gap: 3.75rem;
  width: 100%;
  overflow: hidden;
  // 移动端适配
  @media only screen and (max-width: 48rem) {
    padding: 2rem 0;
  }
}

.more-features-title {
  color: #000000;
  text-align: center;
  font-family: "Inter-Regular", sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  position: relative;
  align-self: stretch;
  padding-bottom: 4rem;
  @media only screen and (max-width: 48rem) {
    font-size: 1.5rem;
    padding-bottom: 2rem;
  }
}

.features-grid {
  display: flex;
  flex-direction: row;
  gap: 0.5625rem;
  row-gap: 1.25rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  flex-shrink: 0;
  position: relative;
}

.feature-card {
  background: #f3f4f6;
  border-radius: 0.625rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  //width: 25em;
  height: 11em;
  position: relative;
  max-width: 24rem;
  @media only screen and (max-width: 48rem) {
    max-width: 20rem;
  }
}

.feature-icon {
  flex-shrink: 0;
  width: 2.3125rem;
  height: 2.25rem;
  position: relative;
  object-fit: cover;
}

.feature-title {
  color: #000000;
  text-align: left;
  font-family: "Inter-Regular", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  position: relative;
}

.feature-description {
  color: #5b5563;
  text-align: left;
  font-family: "Inter-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
}
</style>